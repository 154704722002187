import "./bootstrap";
import "../sass/app.scss";
import "../css/app.css";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { createApp, h } from "vue";
import { ZiggyVue } from "ziggy-js";

createInertiaApp({
    title: (title) => `${title} | DOKKAN.FYI`,
    resolve: (name) =>
        resolvePageComponent(
            `./pages/${name}.vue`,
            import.meta.glob("./pages/**/*.vue"),
        ),
    setup({ el, App, props, plugin }) {
        return createApp({
            render: () => h(App, props),
        })
            .use(ZiggyVue)
            .use(plugin)
            .mount(el);
    },
    progress: {
        color: "#FCD34D",
    },
});
